import { AppFeatures, AustralianState } from '@urbanx/agx-ui-components';
import { useAppSelector } from './useAppSelector';

const defaultAppFeatures: AppFeatures = {
  purchaseTitleDocuments: false,
  requestMarketingFunds: false,
  automateLiveListings: false,
  useConveyUForSolicitor: false,
  addMarketingToLiveListing: false,
  submitListingToRex: false,
  submitContractToRex: false,
};

export const useFeatureToggles = (state?: AustralianState): AppFeatures => {
  const { featureToggles } = useAppSelector(state => state.agentInfo);

  if (!featureToggles || !state) return defaultAppFeatures;

  return featureToggles[state] || defaultAppFeatures;
};
