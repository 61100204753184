import React from 'react';
import {
  AgxCaption,
  AgxColumn,
  AgxLabel,
  AgxRow,
  StakeholderType,
} from '@urbanx/agx-ui-components';
import { PreparedSignatory } from 'utils/vendorBuyerUtil';

interface PreviewSignatoriesProps {
  buyers?: PreparedSignatory[];
  buyerSolicitor?: PreparedSignatory;
  vendors?: PreparedSignatory[];
  vendorSolicitor?: PreparedSignatory;
  stakeholders?: StakeholderType[];
}

const PreviewSignatories: React.FC<PreviewSignatoriesProps> = ({
  buyers = [],
  buyerSolicitor,
  vendors = [],
  vendorSolicitor,
  stakeholders = [],
}) => {
  const renderSignatory = (
    signatory: PreparedSignatory,
    stakeholderName: string
  ) => {
    const signatoryName =
      signatory.name ?? `${signatory.firstName} ${signatory.lastName}`;
    return (
      <AgxRow
        key={`${signatory.id}-${stakeholderName}`}
        veryLargeGap
        spaceBetween
        extraClasses="borderBottom signatory-content"
      >
        <AgxColumn extraLargePadding>
          <AgxLabel>{signatoryName}</AgxLabel>
          <AgxLabel medium colour="neutral-grey-700">
            {signatory.email}
          </AgxLabel>
        </AgxColumn>
        <AgxColumn extraLargePadding extraClasses="fitContent">
          <AgxCaption colour="neutral-grey-700">{stakeholderName}</AgxCaption>
        </AgxColumn>
      </AgxRow>
    );
  };

  const getVendorBuyerStakeholderName = (
    signatory: PreparedSignatory,
    stakeholder: StakeholderType
  ) => {
    return signatory.type === stakeholder
      ? `${stakeholder} ${(signatory.vendorBuyerIndex ?? 0) + 1}`
      : `${signatory.type} (${stakeholder} ${(signatory.vendorBuyerIndex ?? 0) + 1})`;
  };

  return (
    <AgxColumn fill extraClasses="signatory-row borderTop">
      {stakeholders.map(stakeholder => {
        if (stakeholder === StakeholderType.Buyer) {
          return (
            buyers &&
            buyers.length > 0 &&
            buyers.map(signatory => {
              return renderSignatory(
                signatory,
                getVendorBuyerStakeholderName(signatory, stakeholder)
              );
            })
          );
        }
        if (stakeholder === StakeholderType.BuyerSolicitor) {
          return (
            buyerSolicitor &&
            renderSignatory(buyerSolicitor, "Buyer's Solicitor")
          );
        }
        if (stakeholder === StakeholderType.Vendor) {
          return (
            vendors &&
            vendors.length > 0 &&
            vendors.map(signatory => {
              return renderSignatory(
                signatory,
                getVendorBuyerStakeholderName(signatory, stakeholder)
              );
            })
          );
        }
        if (stakeholder === StakeholderType.VendorSolicitor) {
          return (
            vendorSolicitor &&
            renderSignatory(vendorSolicitor, "Vendor's Solicitor")
          );
        }
        return null;
      })}
    </AgxColumn>
  );
};

export default PreviewSignatories;
