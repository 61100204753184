import React from 'react';
import clsx from 'clsx';
import {
  AgxButton,
  AgxColumn,
  AgxLabel,
  AgxRow,
  Images,
  SendConfigurations,
} from '@urbanx/agx-ui-components';
import PreviewSignatories from './PreviewSignatories';
import { PreparedSignatory } from 'utils/vendorBuyerUtil';

interface SendConfirmationProps {
  buyers?: PreparedSignatory[];
  buyerSolicitor?: PreparedSignatory;
  vendors?: PreparedSignatory[];
  vendorSolicitor?: PreparedSignatory;
  onBack: () => void;
  onSend: (sendVendorsNow: boolean) => void;
  sendViaDocuSign?: boolean;
  disabled?: boolean;
  sendConfiguration?: SendConfigurations;
  isMobile?: boolean;
}

const SendConfirmation: React.FC<SendConfirmationProps> = ({
  buyers = [],
  buyerSolicitor,
  vendors = [],
  vendorSolicitor,
  onBack,
  onSend,
  sendViaDocuSign = true,
  disabled = false,
  sendConfiguration,
  isMobile = false,
}) => {
  return (
    <AgxColumn extraLargeGap>
      <AgxLabel large>{sendConfiguration?.subtitle}</AgxLabel>
      <PreviewSignatories
        buyers={buyers}
        buyerSolicitor={buyerSolicitor}
        vendors={vendors}
        vendorSolicitor={vendorSolicitor}
        stakeholders={sendConfiguration?.stakeholders}
      />
      {sendConfiguration?.informationText && (
        <AgxRow fill extraClasses="signatory-row-information">
          <AgxColumn
            veryLargeSidePadding={!isMobile}
            centerJustified
            fill
            extraClasses="signatory-content"
          >
            <AgxRow centered largeGap>
              <Images.InfoOutline />
              <AgxLabel small colour="neutral-grey-700">
                {sendConfiguration?.informationText}
              </AgxLabel>
            </AgxRow>
          </AgxColumn>
        </AgxRow>
      )}
      <AgxColumn
        extraLargePadding
        extraClasses={clsx({
          borderTop: sendConfiguration?.informationText,
          'signatory-row-buttons': true,
        })}
      >
        <AgxRow justifyCenter largeGap>
          <AgxButton
            id="startsubmission"
            large
            hollow
            text="Back"
            onClick={() => onBack()}
            disabled={disabled}
          />
          <AgxButton
            id="cancel"
            primary
            large
            text="Send"
            onClick={() => onSend(sendConfiguration?.sendVendorsNow ?? true)}
            disabled={disabled}
          />
        </AgxRow>
      </AgxColumn>
    </AgxColumn>
  );
};

export default SendConfirmation;
