import React from 'react';
import dayjs from 'dayjs';
import {
  AgxColumn,
  PropertyAddress,
  ContractDetailModel,
  CampaignCompletionState,
  CampaignDetailModel,
  Images,
  AgxBodyText,
} from '@urbanx/agx-ui-components';
import { useFeatureToggles } from 'hooks/useFeatureToggles';
import placeHolderImage from 'assets/images/placeholder-property.png';

import ContractCard from './ContractCard';
import BlankContractProgress from './BlankContractProgress';
import './ContractDetails.scss';

interface ContractDetailsProps {
  campaign: CampaignDetailModel;
  property: PropertyAddress;
  contractDetails: ContractDetailModel[];
  completionState: CampaignCompletionState;
  propertyImageUrls: { thumbnail: string | null };
}

const sortByDate = (a: ContractDetailModel, b: ContractDetailModel) =>
  dayjs(b.lastUpdate).diff(dayjs(a.lastUpdate));

const ContractDetails: React.FC<ContractDetailsProps> = ({
  campaign,
  property,
  contractDetails = [],
  completionState,
  propertyImageUrls: imageUrls,
}) => {
  const { automateLiveListings } = useFeatureToggles(campaign?.state);

  const noContracts = automateLiveListings ? (
    <BlankContractProgress
      blankContractProgress={campaign.blankContractProgress}
    />
  ) : (
    <AgxColumn fill centered centerJustified mediumGap>
      <Images.SparkleForm />
      <AgxBodyText medium>You haven't created a contract yet!</AgxBodyText>
      <AgxBodyText medium>When you do, they'll appear here.</AgxBodyText>
    </AgxColumn>
  );

  const allContracts = contractDetails
    .map((contract: ContractDetailModel) => {
      return {
        ...contract,
        contractStage: contract.contractStage,
        formType: contract.inProgressForm?.formType,
      };
    })
    .sort(sortByDate)
    .map((contract, idx) => (
      <ContractCard
        key={idx}
        state={property.state}
        contract={contract}
        isArchived={completionState.completionType != null}
        propertyImage={imageUrls?.thumbnail ?? placeHolderImage}
      />
    ));

  const showAllContracts = allContracts.length > 0;

  return (
    <>
      <AgxColumn
        extraClasses="contractRequestContracts"
        largeGap
        fill={!showAllContracts && !automateLiveListings}
        centered={!showAllContracts && !automateLiveListings}
        centerJustified={!showAllContracts && !automateLiveListings}
      >
        {showAllContracts ? allContracts : noContracts}
      </AgxColumn>
    </>
  );
};

export default ContractDetails;
