import React, { useEffect, useState } from 'react';
import {
  AgxRow,
  AgxButton,
  AgxColumn,
  DocumentTypes,
  CampaignDetailModel,
  AgxLabel,
  CampaignStage,
  SPECIAL_CHARACTERS,
  FormConfigType,
  AgxSparkleForm,
  AgxCaption,
  useWindowSize,
  Breakpoints,
  MultiOptionButtonOption,
  AgxBodyText,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import { useFeatureToggles } from 'hooks/useFeatureToggles';
import {
  requestGetFileLink,
  useGetFileLink,
} from 'components/ui-components/File/fileApi';
import { useAzureAuth } from 'hooks/useAzureAuth';
import placeHolderImage from 'assets/images/placeholder-property.png';
import './ListingDetails.scss';
import {
  beginMarketingPackage,
  getMarketingPackages,
} from 'Api/Marketing/marketingApi';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useFormLoader } from 'hooks/useFormLoader';
import { MarketingPackageModel } from 'Api/Marketing/Types/Marketing';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import FileList from './FileList';
import { openFileInNewTab } from 'utils/openFileInNewTab';
interface ListingDetailsProps {
  mobile?: boolean;
  campaign: CampaignDetailModel;
  marketingPackage?: MarketingPackageModel;
  setSelectedMarketingPackage: (
    marketingPackage: MarketingPackageModel | undefined
  ) => void;
}

const LISTING_DOCUMENTTYPES: DocumentTypes[] = [
  DocumentTypes.ComparativeMarketAnalysis,
  DocumentTypes.MarketingAgreement,
  DocumentTypes.TitleSearch,
  DocumentTypes.PoolCertificate,
  DocumentTypes.ProofOfIdentity,
  DocumentTypes.MaterialFact,
  DocumentTypes.PowerOfAttorney,
  DocumentTypes.Miscellaneous,
  DocumentTypes.AgencyAgreement,
];

const ListingDetails: React.FC<ListingDetailsProps> = ({
  mobile = false,
  campaign,
  marketingPackage,
  setSelectedMarketingPackage,
}) => {
  dayjs.extend(relativeTime);
  const [, getAuthToken] = useAzureAuth();
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const {
    propertyImageUrls,
    documents = [],
    completionState,
    lastUpdated,
  } = campaign;
  const { addMarketingToLiveListing } = useFeatureToggles(campaign?.state);
  const { loadForm } = useFormLoader();
  const imageUrl = propertyImageUrls?.medium ?? '';
  const isDesktop = windowSize === Breakpoints.Desktop;
  const getFileLink = useGetFileLink();
  const [marketingPackages, setMarketingPackages] = useState<
    MarketingPackageModel[]
  >([]);

  const filterListingDocuments = documents
    .filter(document => LISTING_DOCUMENTTYPES.includes(document.documentType))
    .map(
      listingDocument =>
        ({
          text: listingDocument.fileName,
          documentType: listingDocument.documentType,
          onClick: async () => {
            const fileLink = await getFileLink(
              listingDocument.containerFilePath
            );
            if (fileLink !== null) {
              openFileInNewTab(isDesktop, fileLink);
            }
          },
        }) as MultiOptionButtonOption
    );

  const getAgencyAgreementLink = async () => {
    const agencyAgreementCampaign = documents.find(
      d => d.documentType === DocumentTypes.AgencyAgreement
    );

    if (!agencyAgreementCampaign) {
      return null;
    }

    const authToken = await getAuthToken();

    if (!authToken) return null;

    const link = await requestGetFileLink(
      authToken,
      agencyAgreementCampaign.containerFilePath
    );

    return link;
  };

  const onDownloadAgencyAgreementClick = async () => {
    const link = await getAgencyAgreementLink();
    if (link !== null) {
      // window.open does not work with latest safari browser
      // Workaround: wrap the window.open call to settimeout.
      // setTimeout code runs on the main thread, instead of the asynchronous one.
      setTimeout(() => {
        window.open(link, '_blank');
      });
    }
  };

  const fetchMarketingPackages = async () => {
    const authToken = await getAuthToken();
    if (!authToken) return null;
    const data = await getMarketingPackages(authToken, campaign.campaignId);
    setMarketingPackages(data ?? []);

    if (marketingPackage) {
      setSelectedMarketingPackage(
        data.find(mp => mp.id === marketingPackage.id)
      );
    }
  };

  const onLoadMarketingPackage = async (
    campaignId: string,
    marketingPackage: MarketingPackageModel
  ) => {
    const formId = marketingPackage.id;
    const stage = marketingPackage.stage.value;
    setSelectedMarketingPackage(marketingPackage);
    if (stage === 'Draft')
      await loadForm(campaign.campaignId, formId, FormConfigType.Marketing);
  };

  const onBeginMarketingPackage = async () => {
    const authToken = await getAuthToken();
    if (!authToken) return null;
    const { formId } = await beginMarketingPackage(
      authToken,
      dispatch,
      campaign.campaignId
    );
    await loadForm(campaign.campaignId, formId, FormConfigType.Marketing);
  };

  useEffect(() => {
    if (addMarketingToLiveListing) fetchMarketingPackages();
  }, [addMarketingToLiveListing, lastUpdated]);

  return (
    <AgxColumn centered veryLargeGap extraClasses="contractListingDetails">
      <AgxRow centered justifyCenter extraClasses={'listing-imageCard'}>
        <div
          style={{
            backgroundImage: `url("${imageUrl || placeHolderImage}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            aspectRatio: '2/1',
          }}
          className={`imageCard-image ${completionState.completionType ? 'archived' : ''}`}
        >
          <div className="image-tag">
            <AgxLabel medium colour="brand-text-button-color">
              {
                CampaignStage[
                  campaign.stage as unknown as keyof typeof CampaignStage
                ].split(SPECIAL_CHARACTERS.BULLET)[1]
              }
            </AgxLabel>
          </div>
        </div>
      </AgxRow>

      {addMarketingToLiveListing && (
        <AgxColumn fill mediumGap>
          <AgxLabel medium>Marketing</AgxLabel>
          {marketingPackages.map(marketingPackage => (
            <AgxRow
              key={marketingPackage.id}
              extraClasses={'marketingPackageListItem'}
              largeGap
              onClick={() =>
                onLoadMarketingPackage(campaign.campaignId, marketingPackage)
              }
            >
              <AgxColumn>
                <AgxSparkleForm width={50} height={50} />
              </AgxColumn>
              <AgxColumn extraClasses={'textContainerWidth'}>
                <AgxRow spaceBetween>
                  <AgxCaption colour="neutral-grey-700">
                    {marketingPackage.stage.value}
                  </AgxCaption>
                  <AgxCaption colour="neutral-grey-700">
                    {dayjs(marketingPackage.lastUpdated).fromNow()}
                  </AgxCaption>
                </AgxRow>
                <AgxRow>
                  <AgxBodyText extraClasses="showEllipses">
                    {marketingPackage?.packageDisplayName
                      ? marketingPackage?.packageDisplayName
                      : marketingPackage?.packageTemplateName}
                  </AgxBodyText>
                </AgxRow>
              </AgxColumn>
            </AgxRow>
          ))}
          <AgxButton
            hollow
            text={`Add Marketing`}
            medium
            wide={mobile}
            onClick={async () => onBeginMarketingPackage()}
          />
        </AgxColumn>
      )}
      <AgxColumn fill extraClasses={'fileListContainer'}>
        <FileList files={filterListingDocuments} title="Listing Documents" />
      </AgxColumn>
    </AgxColumn>
  );
};

export default ListingDetails;
