import { StakeholderType, VendorBuyer } from '@urbanx/agx-ui-components';

export interface PreparedSignatory {
  id: string;
  name: string;
  companyName?: string;
  principalName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  preferredName?: string;
  email: string;
  type: StakeholderType;
  vendorBuyerIndex?: number;
}

export const prepareSignatories = (
  vendorBuyerArray: VendorBuyer[] = [],
  type: StakeholderType
) => {
  let result: PreparedSignatory[] = [];

  vendorBuyerArray?.forEach((obj, ind) => {
    if (obj.individual) {
      const { name, email } = obj.individual;
      const vendorName = `${name.firstName} ${name.middleName ?? ''} ${
        name.lastName
      }`;
      result.push({
        id: obj.id,
        name: vendorName,
        firstName: name.firstName,
        middleName: name.middleName ?? '',
        lastName: name.lastName,
        preferredName: name.preferredName ?? '',
        email: email,
        type: type,
        vendorBuyerIndex: ind,
      });
    } else if (obj.company) {
      const { companyName, signatories } = obj.company;

      signatories.forEach(signatory => {
        const { id, name, email } = signatory;
        result.push({
          id: id,
          name: `${name.firstName} ${name.middleName ?? ''} ${name.lastName}`,
          companyName: companyName,
          firstName: name.firstName,
          middleName: name.middleName ?? '',
          lastName: name.lastName,
          preferredName: name.preferredName ?? '',
          email: email,
          type: StakeholderType.Signatory,
          vendorBuyerIndex: ind,
        });
      });
    } else if (obj.powerOfAttorney) {
      const { principalName, attorneys } = obj.powerOfAttorney;

      if (attorneys && attorneys.length > 0) {
        attorneys.forEach(attorney => {
          const { id, email, name } = attorney;
          result.push({
            id: id,
            principalName: `${principalName.firstName} ${
              principalName.middleName ?? ''
            } ${principalName.lastName}`,
            name: `${name.firstName} ${name.middleName ?? ''} ${name.lastName}`,
            firstName: name.firstName,
            middleName: name.middleName ?? '',
            lastName: name.lastName,
            preferredName: name.preferredName ?? '',
            email: email,
            type: StakeholderType.Attorney,
            vendorBuyerIndex: ind,
          });
        });
      }
    } else if (obj.deceasedEstate) {
      const { executors } = obj.deceasedEstate;

      if (executors && executors.length > 0) {
        executors.forEach(executor => {
          const { id, name, email } = executor;
          result.push({
            id: id,
            name: `${name.firstName} ${name.middleName ?? ''} ${name.lastName}`,
            firstName: name.firstName,
            middleName: name.middleName ?? '',
            lastName: name.lastName,
            preferredName: name.preferredName ?? '',
            email: email,
            type: StakeholderType.Executor,
            vendorBuyerIndex: ind,
          });
        });
      }
    }
  });

  return result;
};

export const getAllBuyerNames = (allBuyers: PreparedSignatory[]) => {
  return allBuyers
    .map(buyer => {
      switch (buyer.type) {
        case StakeholderType.Buyer:
          return buyer.name;
        case StakeholderType.Attorney:
          return buyer.principalName;
        case StakeholderType.Signatory:
          return buyer.companyName;
        default:
          return '';
      }
    })
    .filter(name => !!name) as string[];
};
