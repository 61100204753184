import { useEffect, useState, useMemo } from 'react';
import {
  AgxButton,
  AgxRow,
  AgxColumn,
  AgxHeader,
  AgxLabel,
  Images,
  AgxBodyText,
  AgxSlideUpModal,
  DocumentTypes,
  FormType,
  DocumentTypesMap,
  AgxMultiOptionButton,
  MultiOptionButtonOption,
  lowerCaseFirstLetter,
  FormConfigType,
} from '@urbanx/agx-ui-components';
import isEqual from 'lodash/isEqual';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setAndShowErrorToast } from 'store/config';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { completeCampaignStep } from 'Api/Campaigns/campaignsApi';
import { loadFormConfig } from '../../form/formReducer';
import usePropertyImageSelector from 'selectors/usePropertyImageSelector';
import { useFormSettings } from 'hooks/useFormSettings';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { useGetFileLink } from 'components/ui-components/File/fileApi';
import placeHolderImage from '../../../assets/images/placeholder-property.png';
import ErrorModal from '../../../helpers/ErrorModal';
import useFormCampaignId from 'hooks/useFormCampaignId';
import useFormId from 'hooks/useFormId';
import { SendViaDocuSignForm } from './SendViaDocuSignForm';
import './ReviewAndSign.scss';
import useListingDocumentsSelector from 'selectors/useListingDocumentsSelector';
import { openFileInNewTab } from 'utils/openFileInNewTab';
import { sortDocumentsByType } from 'utils/documentUtil';
import { loadMarketingPackageForm } from 'Api/Marketing/marketingApi';
import ContractReviewAndSign from '../ContractReviewAndSign/ContractReviewAndSign';

interface ReviewAndSignProps {
  goToFirstPage: () => void;
  agentSignsFirst: boolean;
  emailStyle: string;
}

const ReviewAndSign = ({
  goToFirstPage,
  agentSignsFirst,
  emailStyle,
}: ReviewAndSignProps) => {
  const [showSendDocuSignPage, setShowSendDocuSignPage] = useState(false);

  const updateFormSettings = useFormSettings();
  const getFileLink = useGetFileLink();
  const dispatch = useAppDispatch();
  const [, getAuthToken] = useAzureAuth();

  const isDesktop = ScreenSize() === Breakpoints.Desktop;
  const [formName, setFormName] = useState<string | undefined>();
  const propertyImages = usePropertyImageSelector();

  const { formErrors, generatedFormDocuments, selectedForm } = useAppSelector(
    state => state.form
  );
  const { formType, state, formConfigType } = selectedForm || {};
  const campaignId = useFormCampaignId();
  const formId = useFormId();
  const availableForms = useAppSelector(state => state.forms.availableForms);

  const agencyAgreement = generatedFormDocuments.find(
    d => d.documentType === DocumentTypes.AgencyAgreement
  );

  const mergedAgencyAgreement = generatedFormDocuments.find(
    d => d.documentType === DocumentTypes.MergedAgencyAgreement
  );

  const isMobile = ScreenSize() === Breakpoints.Mobile;
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const documents = useListingDocumentsSelector();

  const getFormName = async () => {
    let formName: string | undefined = undefined;
    if (formConfigType === FormConfigType.Campaign) {
      formName = availableForms.find(
        form => form.type === formType && isEqual(form.state, state)
      )?.name;
    } else {
      const authToken = await getAuthToken();
      if (!authToken || !formId) return;

      const form = await loadMarketingPackageForm(authToken, formId);
      if (form) formName = form.name;
    }
    setFormName(formName);
  };

  const noAttachments = useMemo(() => {
    const documentTypes = documents.map(doc => doc.documentType);
    const uniqueTypes = new Set(documentTypes);
    // Check if the unique types are one 'AgencyAgreement' and one 'MergedAgencyAgreement'
    return (
      uniqueTypes.size === 2 &&
      uniqueTypes.has(DocumentTypes.AgencyAgreement) &&
      uniqueTypes.has(DocumentTypes.MergedAgencyAgreement)
    );
  }, [documents]);

  const fileDownloadOptions = useMemo(() => {
    if (!documents || documents.length === 0) return [];
    const options: MultiOptionButtonOption[] = [];

    const orderedDocuments = sortDocumentsByType(documents);

    orderedDocuments?.forEach(d => {
      options.push({
        text: DocumentTypesMap[d.documentType],
        onClick: async () => {
          const fileLink = await getFileLink(d.containerFilePath);
          if (fileLink != null) {
            openFileInNewTab(isDesktop, fileLink);
          }
        },
      });
    });
    return options;
  }, [documents, getFileLink]);

  const viewPdfButton = () => {
    if (fileDownloadOptions.length === 0) return;

    if (fileDownloadOptions.length === 1 || noAttachments) {
      return (
        <AgxButton
          text="Download"
          hollow
          medium
          wide={isMobile}
          onClick={fileDownloadOptions[0].onClick}
          dataTestId={`agx-${lowerCaseFirstLetter(formName as string).replace(' ', '')}`}
        />
      );
    } else {
      return (
        <AgxMultiOptionButton
          id="downloadAgencyAgreement"
          text="Download"
          hollow
          medium
          wide={isMobile}
          options={fileDownloadOptions}
          isWidthFitContent
          dataTestId={`agx-${lowerCaseFirstLetter(formName as string).replace(' ', '')}`}
        />
      );
    }
  };

  useEffect(() => {
    updateFormSettings({
      viewPreFill: false,
    });
  }, [showSendDocuSignPage]);

  useEffect(() => {
    if (formId) {
      getFormName();
    }
  }, [formId]);

  const beginAgencyAgreementSubmission = async () => {
    if (!campaignId || !formId) return;

    const agencyAgreementSubmissionForm = availableForms.find(
      form =>
        form.type === FormType.ListingSubmission &&
        selectedForm &&
        isEqual(form.state, selectedForm.state)
    );

    if (!agencyAgreementSubmissionForm) {
      dispatch(
        setAndShowErrorToast('Failed to start Agency Agreement submission')
      );
      return;
    }

    try {
      setDisableButtons(true);

      const authToken = await getAuthToken();

      if (!authToken) {
        setDisableButtons(false);
        return;
      }

      const newForm = await completeCampaignStep(
        authToken,
        campaignId,
        true,
        formId
      );

      if (!newForm) {
        dispatch(
          setAndShowErrorToast('Failed to start Agency Agreement submission')
        );
        setDisableButtons(false);
        return;
      }

      dispatch(loadFormConfig(newForm));
    } catch (err) {
      dispatch(
        setAndShowErrorToast('Failed to start Agency Agreement submission')
      );
    }

    setDisableButtons(false);
  };

  if (!formName) return;
  if (showSendDocuSignPage && formConfigType === FormConfigType.Campaign)
    return (
      <SendViaDocuSignForm
        agentSignsFirst={agentSignsFirst}
        onGoBack={() => {
          setShowSendDocuSignPage(false);
          updateFormSettings({
            showBreadcrumbs: true,
            displayTitleVisible: true,
          });
        }}
        emailStyle={emailStyle}
      />
    );

  const confirmationDialogBody = (
    <AgxColumn veryLargeGap extraClasses={'confirmationModalStyle'}>
      <Images.AlertCircleOutline />
      <AgxHeader size={3}> Starting a submission?</AgxHeader>
      <AgxBodyText medium>
        If you have a complete and signed {formName} PDF you can submit it now.
      </AgxBodyText>
      <AgxBodyText medium>
        You will not be able to edit the {formName} once you continue.
      </AgxBodyText>
      <div className={'confirmationButtonContainerStyle'}>
        <AgxButton
          dataTestId="agx-startSubmission-button"
          id="startsubmission"
          large
          primary
          text="Yes, start submission"
          disabled={disableButtons}
          onClick={beginAgencyAgreementSubmission}
        />
        <AgxButton
          dataTestId="agx-noGoBack-button"
          id="cancel"
          hollow
          large
          text="No, go back"
          disabled={disableButtons}
          onClick={() => setShowConfirmationModel(false)}
        />
      </div>
    </AgxColumn>
  );

  return (
    <AgxColumn extraClasses="reviewAndSignImageCard">
      <AgxColumn
        veryLargeGap
        extraLargePadding
        centered
        extraClasses="agreementDetails"
      >
        <AgxHeader size={4} centered>
          {' '}
          {`Review your ${formName} before proceeding:`}
        </AgxHeader>
        <img
          className="propertyImage"
          src={propertyImages?.medium ?? placeHolderImage}
          alt="Avatar"
        />
        <AgxRow largeGap centered justifyCenter={isMobile} fill={isMobile}>
          {viewPdfButton()}
          <AgxButton
            dataTestId="agx-edit-button"
            text="Edit"
            onClick={goToFirstPage}
            hollow
            medium
            disabled={disableButtons}
            wide={isMobile}
          />
        </AgxRow>
      </AgxColumn>
      <div className="signingGrid">
        <AgxColumn largeGap centered extraClasses="signingGridCell">
          <AgxLabel large> DocuSign </AgxLabel>
          <AgxButton
            dataTestId="agx-sendViaDocuSign-button"
            primary
            large
            text="Send via DocuSign"
            onClick={() => {
              if (formErrors.length > 0) {
                setOpenErrorModal(true);
              } else {
                setShowSendDocuSignPage(true);
                updateFormSettings({
                  showBreadcrumbs: isMobile ? false : true,
                  displayTitleVisible: false,
                });
              }
            }}
            disabled={disableButtons}
            wide={isMobile}
          />
          {formErrors.length > 0 && (
            <AgxRow
              centered
              veryLargeGap
              extraClasses={'toastStyle'}
              fill={isMobile}
            >
              <span>
                <Images.AlertCircle />
              </span>
              <AgxBodyText>
                Please complete required fields before DocuSigning
                <br />
                <AgxButton
                  dataTestId="agx-viewDetails-button"
                  text="View Details"
                  naked
                  extraClasses={'hyperlink'}
                  onClick={() => setOpenErrorModal(true)}
                />
              </AgxBodyText>
            </AgxRow>
          )}
        </AgxColumn>
        <AgxColumn largeGap centered extraClasses="signingGridCell">
          <AgxLabel large> Sign in person </AgxLabel>
          <AgxButton
            dataTestId="agx-downloadToSign-button"
            primary
            large
            text="Download to Sign"
            onClick={async () => {
              var agreement = mergedAgencyAgreement
                ? mergedAgencyAgreement
                : agencyAgreement;
              if (agreement) {
                const fileLink = await getFileLink(agreement.containerFilePath);
                openFileInNewTab(isDesktop, fileLink);
              }
            }}
            wide={isMobile}
          />
          {(mergedAgencyAgreement
            ? mergedAgencyAgreement
            : agencyAgreement) && (
            <AgxButton
              id="agx-submitSignedPDF-button"
              hollow
              large
              text="Submit Signed PDF"
              onClick={() => setShowConfirmationModel(true)}
              wide={isMobile}
              disabled={disableButtons}
            />
          )}
        </AgxColumn>
      </div>
      {openErrorModal && (
        <ErrorModal errorList={formErrors} setShowModal={setOpenErrorModal} />
      )}
      {showConfirmationModel && (
        <AgxSlideUpModal
          closeButton
          content={confirmationDialogBody}
          desktop={!isMobile}
          onClose={() => setShowConfirmationModel(false)}
        />
      )}
    </AgxColumn>
  );
};

export default ReviewAndSign;
