import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { LoadingState } from 'utils/loadingState';
import { fetchAgents } from '../../agencies/agenciesReducer';

const ReviewAndSignPreloader = () => {
  const dispatch = useAppDispatch();
  const [, getAuthToken] = useAzureAuth();
  const loadingState = useAppSelector(
    state => state.agencies.agents.loadingState
  );

  useEffect(() => {
    if (loadingState !== LoadingState.NotLoaded) return;

    getAuthToken().then(async token => {
      if (!token) return;
      dispatch(fetchAgents(token));
    });
  }, [loadingState]);

  return null;
};

export default ReviewAndSignPreloader;
